declare global {
    interface Window {
        dataLayer: any[];
    }
}

declare function fbq(action: string, eventName: string): void;
declare function gtag(action: string, eventName: string, options?: { [key: string]: any }): void;

interface SocialMediaTag {
    old: string;
    new: string;
}

export const SOCIAL_MEDIA_TAGS: Record<string, SocialMediaTag> = {
    LEAD: {
        old: 'Lead',
        new: 'lead_conversion'
    },
    LANDING_MAIN_PAGE: {
        old: process.env.REACT_APP_LANDING_MAIN_PAGE || '',
        new: 'landing_main_page_conversion'
    },
    LANDING_PRICE_PAGE: {
        old: process.env.REACT_APP_LANDING_PRICE_PAGE || '',
        new: 'landing_price_page_conversion'
    },
    LANDING_CONTACT_PAGE: {
        old: process.env.REACT_APP_LANDING_CONTACT_PAGE || '',
        new: 'landing_contact_page_conversion'
    },
    LANDING_CONTACTO: {  // Nueva entrada agregada
        old: process.env.REACT_APP_LANDING_CONTACTO || '',
        new: 'landing_contact_page_conversion'
    },
    LANDING_CONTACT_NOW: {
        old: process.env.REACT_APP_LANDING_CONTACT_NOW || '',
        new: 'landing_contact_now_conversion'
    },
    LANDING_QUICK_CONTACT: {
        old: process.env.REACT_APP_LANDING_QUICK_CONTACT || '',
        new: 'landing_quick_contact_conversion'
    }
};

/**
 * Tracks a conversion event for the specified social media tag.
 * @param tag - The tag key to track the conversion.
 */
export const trackConversion = (tag: keyof typeof SOCIAL_MEDIA_TAGS): void => {
    try {
        const { new: newTag } = SOCIAL_MEDIA_TAGS[tag];
        if (process.env.REACT_APP_ENVIRONMENT === 'production' && newTag) {
            if (typeof gtag === 'function') {
                gtag('event', 'conversion', {
                    'send_to': newTag,
                });
            }
        }
    } catch (error) {
        console.error(`Error tracking conversion for ${tag}:`, error);
    }
};

/**
 * Tracks lead conversion event for Facebook Pixel.
 */
export const metaLeadTrack = (): void => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        fbq('track', SOCIAL_MEDIA_TAGS.LEAD.old);
    }
};

export function gtagLandingMainPageConversion() {
    trackConversion('LANDING_MAIN_PAGE');
}

export function gtagLandingPricePageConversion() {
    trackConversion('LANDING_PRICE_PAGE');
}

export function gtagLandingContactPageConversion() {
    trackConversion('LANDING_CONTACT_PAGE');
}

export function gtagLandingContactoConversion() {
    trackConversion('LANDING_CONTACTO');
}

export function gtagLandingContactNowConversion() {
    trackConversion('LANDING_CONTACT_NOW');
}

export function gtagLandingQuickContactConversion() {
    trackConversion('LANDING_QUICK_CONTACT');
}

// Manejo del envío de formularios
const handleFormSubmit = (formName: string, formData: { name: string; email: string; password?: string; }) => {
    // Validación de los campos
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const namePattern = /^[a-zA-Z\s]+$/;

    const isFormValid = namePattern.test(formData.name) && 
                        emailPattern.test(formData.email) && 
                        (formData.password ? formData.password.length >= 6 : true);

    window.dataLayer = window.dataLayer || [];
    
    if (isFormValid) {
        window.dataLayer.push({
            'event': formName,
            'formStatus': 'success'
        });

        switch (formName) {
            case 'home':
                gtagLandingMainPageConversion();
                break;
            case 'contact':
                gtagLandingContactPageConversion();
                break;
            case 'contacto':
                gtagLandingContactoConversion();
                break;
            case 'pricing':
                gtagLandingPricePageConversion();
                break;
            default:
                console.warn(`No tracking configured for form: ${formName}`);
        }
    } else {
        window.dataLayer.push({
            'event': formName,
            'formStatus': 'error'
        });
        console.error(`Form submission failed for ${formName}`);
    }
};

import {LeadInterface} from "../../interfaces/leadInterface";

export const leadBase: LeadInterface = {
    origin: {
      origin: ""
    },
    property: {
        address: "",
        bedrooms: 2,
        bathrooms: 2,
        availability: 'fulltime',
        accommodates: 4,
        extras: [],
        owner: 'yes',
        hire: 'yes',
        type_hire: null,
        licence: 'yes',
        call: 'yes'
    },
    user: {
        name: "",
        email: "",
        phone: "",
        political_acceptance: 'yes'
    }
}


export const leadFormMainBase: LeadInterface = {
    origin: {
        origin: "main"
    },
    property: {
        address: "",
        bedrooms: 2,
        bathrooms: 2,
        availability: null,
        accommodates: 4,
        extras: null,
        owner: null,
        hire: null,
        type_hire: null,
        licence: null,
        call: null
    },
    user: {
        name: "",
        email: "",
        phone: "",
        political_acceptance: null
    }
}


export const leadFormLandingBase: LeadInterface = {
    origin: {
        origin: "landing"
    },
    property: {
        address: "",
        bedrooms: null,
        bathrooms: null,
        availability: null,
        accommodates: null,
        extras: null,
        owner: null,
        hire: null,
        type_hire: null,
        licence: null,
        call: null
    },
    user: {
        name: "",
        email: "",
        phone: "",
        political_acceptance: 'yes'
    }
}

export const leadFormContactNowBase: LeadInterface = {
    origin: {
        origin: "landing_contact_now"
    },
    property: {
        address: "Not defined",
        bedrooms: null,
        bathrooms: null,
        availability: null,
        accommodates: null,
        extras: null,
        owner: null,
        hire: null,
        type_hire: null,
        licence: null,
        call: null
    },
    user: {
        name: "",
        email: "",
        phone: "",
        political_acceptance: 'yes'
    }
}

export const leadFormContactPropertiesBase: LeadInterface = {
    origin: {
        origin: "landing_contact_properties"
    },
    property: {
        address: "Not defined",
        bedrooms: null,
        bathrooms: null,
        availability: null,
        accommodates: null,
        extras: null,
        owner: null,
        hire: null,
        type_hire: null,
        licence: null,
        call: null
    },
    user: {
        name: "",
        email: "",
        phone: "",
        political_acceptance: 'yes'
    }
}

export const leadFormQuickContactBase: LeadInterface = {
    origin: {
        origin: "quick_contact"
    },
    property: {
        address: "Not defined",
        bedrooms: null,
        bathrooms: null,
        availability: null,
        accommodates: null,
        extras: null,
        owner: null,
        hire: null,
        type_hire: null,
        licence: null,
        call: null
    },
    user: {
        name: "",
        email: "",
        phone: "",
        political_acceptance: 'yes'
    }
}
